import { useCurrentUserStore } from '~/stores/currentUser'

const ACCESS_TOKEN_KEY = 'auth.accessToken'
const VIRTUAL_ACCESS_TOKEN = 'auth.virtualAccessToken'

export default class AuthTokenStorer {
  static set(token: string) {
    localStorage.setItem(ACCESS_TOKEN_KEY, token)
  }

  static setVirtualToken(token: string) {
    return sessionStorage.setItem(VIRTUAL_ACCESS_TOKEN, token)
  }

  static get(): string | null {
    const { isVirtual } = useCurrentUserStore()

    if (isVirtual) {
      return sessionStorage.getItem(VIRTUAL_ACCESS_TOKEN)
    } else {
      return localStorage.getItem(ACCESS_TOKEN_KEY)
    }
  }

  static clear() {
    localStorage.removeItem(ACCESS_TOKEN_KEY)
  }
}
