import {
  startOfISOWeek,
  setISOWeek,
  endOfISOWeek,
  startOfMonth,
  lastDayOfMonth,
  lastDayOfYear,
  startOfYear,
  max,
  min,
  addDays,
  addMonths,
  isFuture,
  startOfQuarter,
  lastDayOfQuarter,
  setQuarter
} from 'date-fns'
import { getGranularity } from './analytics'
import { positiveMod } from './number'
import { DIMENSION_TITLES } from '~/constants/analytics'
import {
  ALLOWED_SECONDARY_TIME_DIMENSION_BY_MAIN_TIME_DIMENSION,
  ALL_FISCAL_TIME_DIMENSION_TITLES,
  MAIN_FISCAL_TIME_DIMENSION_TITLES,
  ORDERED_TIME_GRAINS,
  SECONDARY_FISCAL_TIME_DIMENSION_TITLES,
  SECONDARY_DEFAULT_TIME_DIMENSION_TITLES,
  TIME_GRAIN_BY_TIME_DIMENSION,
  ALL_SECONDARY_TIME_DIMENSION_TITLES,
  ALL_MAIN_TIME_DIMENSION_TITLES,
  ALL_TIME_DIMENSION_TITLES,
  MAIN_DEFAULT_TIME_DIMENSION_TITLES
} from '~/constants/timeDimension'
import {
  getFiscalFormat,
  getFiscalQuarterDateRange,
  getFiscalYearDateRange,
  getQuarterPeriodByYearAndQuarterNumber,
  getRangeLength,
  getYearStartDate
} from '~/services/period'
import {
  formatDate,
  formatDate2,
  getDayOfTheWeek,
  getMonth,
  getQuarter,
  getShortMonth,
  getShortYear,
  isDateAfter,
  isDateBefore
} from '~/services/date'
import {
  TimeGrain,
  type FullPeriod,
  CustomTimeGrain
} from '~/types/timeDimension'
import type { DimensionOption, DimensionWithValue } from '~/types/analytics'
import { useCustomizationStore } from '~/stores/customization'
import type { Dimension } from '~/types/cube'

export const getAllowedTimeDimensionsFromDateRange = (
  dateRange: [string, string]
) => {
  const diffDays = getRangeLength(dateRange)
  const maxTimeGrain =
    (diffDays >= 365 && TimeGrain.YEAR) ||
    (diffDays >= 90 && TimeGrain.QUARTER) ||
    (diffDays >= 30 && TimeGrain.MONTH) ||
    (diffDays >= 7 && TimeGrain.WEEK) ||
    TimeGrain.DAY
  const maxTimeGrainIndex = ORDERED_TIME_GRAINS.indexOf(maxTimeGrain)
  const allowedTimeGrains = ORDERED_TIME_GRAINS.filter(
    (_, index) => index <= maxTimeGrainIndex
  )
  return (
    Object.keys(
      TIME_GRAIN_BY_TIME_DIMENSION
    ) as (typeof ALL_TIME_DIMENSION_TITLES)[number][]
  ).filter(timeDimensionTitle =>
    allowedTimeGrains.includes(TIME_GRAIN_BY_TIME_DIMENSION[timeDimensionTitle])
  )
}

export const filterAvailableTimeDimensions = (
  unfilteredDimensions: DimensionOption[],
  dimensionTitlesAlreadySelected: string[],
  selectedPeriod: FullPeriod
): DimensionOption[] => {
  const { isInFiscalMode } = useCustomizationStore()

  const filteredDimensions = unfilteredDimensions.filter(
    dimension =>
      isInFiscalMode ||
      !ALL_FISCAL_TIME_DIMENSION_TITLES.includes(
        dimension.shortTitle as (typeof ALL_FISCAL_TIME_DIMENSION_TITLES)[number]
      )
  )

  const mainTimeDimension = dimensionTitlesAlreadySelected.find(
    dimensionTitle => isMainTimeDimension(dimensionTitle)
  ) as (typeof ALL_MAIN_TIME_DIMENSION_TITLES)[number] | undefined

  const secondaryTimeDimension = dimensionTitlesAlreadySelected.find(
    dimensionTitle => isSecondaryTimeDimension(dimensionTitle)
  ) as (typeof ALL_SECONDARY_TIME_DIMENSION_TITLES)[number] | undefined

  // If main and secondary time dimensions are selected, user can not select any additional time dimension
  if (mainTimeDimension && secondaryTimeDimension) {
    return filteredDimensions.filter(
      dimension =>
        !ALL_TIME_DIMENSION_TITLES.includes(
          dimension.shortTitle as (typeof ALL_TIME_DIMENSION_TITLES)[number]
        )
    )
  }
  // If only main time dimension is selected, user can only select allowed secondary time dimensions
  else if (mainTimeDimension) {
    const allowedTimeDimensions =
      ALLOWED_SECONDARY_TIME_DIMENSION_BY_MAIN_TIME_DIMENSION[mainTimeDimension]

    return filteredDimensions.filter(
      dimension =>
        !ALL_TIME_DIMENSION_TITLES.includes(
          dimension.shortTitle as (typeof ALL_TIME_DIMENSION_TITLES)[number]
        ) ||
        allowedTimeDimensions.includes(
          dimension.shortTitle as (typeof ALL_SECONDARY_TIME_DIMENSION_TITLES)[number]
        )
    )
  }
  // If only secondary time dimension is selected, user can only select allowed main time dimension
  else if (secondaryTimeDimension) {
    const allowedMainTimeDimension = (
      Object.keys(
        ALLOWED_SECONDARY_TIME_DIMENSION_BY_MAIN_TIME_DIMENSION
      ) as (typeof ALL_MAIN_TIME_DIMENSION_TITLES)[number][]
    ).find(mainDimension =>
      ALLOWED_SECONDARY_TIME_DIMENSION_BY_MAIN_TIME_DIMENSION[
        mainDimension
      ].find(dim => dim === secondaryTimeDimension)
    )!

    return filteredDimensions.filter(
      dimension =>
        !ALL_TIME_DIMENSION_TITLES.includes(
          dimension.shortTitle as (typeof ALL_TIME_DIMENSION_TITLES)[number]
        ) ||
        allowedMainTimeDimension ===
          (dimension.shortTitle as (typeof ALL_MAIN_TIME_DIMENSION_TITLES)[number])
    )
  }
  // If no time dimensions are selected, user can select any time dimensions allowed for the selected date range
  else {
    const allowedTimeDimensions = getAllowedTimeDimensionsFromDateRange(
      selectedPeriod.dateRange as [string, string]
    )

    return filteredDimensions.filter(
      dimension =>
        !ALL_TIME_DIMENSION_TITLES.includes(
          dimension.shortTitle as (typeof ALL_TIME_DIMENSION_TITLES)[number]
        ) ||
        allowedTimeDimensions.includes(
          dimension.shortTitle as (typeof ALL_TIME_DIMENSION_TITLES)[number]
        )
    )
  }
}

export const getFormattedMainTimeDimensionPeriod = (
  periodValue: string,
  dimensionTitle: (typeof ALL_MAIN_TIME_DIMENSION_TITLES)[number]
): string => {
  const { $t } = useNuxtApp()

  const isFiscal = MAIN_FISCAL_TIME_DIMENSION_TITLES.includes(
    dimensionTitle as (typeof MAIN_FISCAL_TIME_DIMENSION_TITLES)[number]
  )

  switch (dimensionTitle) {
    case DIMENSION_TITLES.DATE_DAY: {
      return formatDate(periodValue)
    }

    case DIMENSION_TITLES.DATE_WEEK: {
      const [isoYear, isoWeekNumber] = periodValue
        .split('.')
        .map(value => parseInt(value)) as [number, number]
      // Set the ISO week and ISO year (starting from the first day of the year)
      const dateInISOWeek = setISOWeek(new Date(isoYear, 0, 4), isoWeekNumber)

      // Get the start of the ISO week (first day of the week)
      const firstDayOfISOWeek = startOfISOWeek(dateInISOWeek)
      return formatDate(firstDayOfISOWeek)
    }

    case DIMENSION_TITLES.DATE_MONTH: {
      const [year, month] = periodValue
        .split('.')
        .map(value => parseInt(value)) as [number, number]

      return `${$t(getShortMonth(month))} ${getShortYear(year)}`
    }

    case DIMENSION_TITLES.DATE_FISCAL_QUARTER:
    case DIMENSION_TITLES.DATE_QUARTER: {
      const [year, quarter] = periodValue
        .split('.')
        .map(value => parseInt(value)) as [number, number]

      return getFiscalFormat(`${$t(getQuarter(quarter))} - ${year}`, isFiscal)
    }

    case DIMENSION_TITLES.DATE_FISCAL_YEAR:
    case DIMENSION_TITLES.DATE_YEAR: {
      return getFiscalFormat(periodValue, isFiscal)
    }
  }
}

export const getFormattedSecondaryTimeDimensionPeriod = (
  periodValue: number,
  dimensionTitle: (typeof ALL_SECONDARY_TIME_DIMENSION_TITLES)[number]
): string => {
  const { $t } = useNuxtApp()

  const isFiscal = SECONDARY_FISCAL_TIME_DIMENSION_TITLES.includes(
    dimensionTitle as (typeof SECONDARY_FISCAL_TIME_DIMENSION_TITLES)[number]
  )

  switch (dimensionTitle) {
    case DIMENSION_TITLES.DATE_FISCAL_QUARTER_OF_THE_FISCAL_YEAR:
    case DIMENSION_TITLES.DATE_QUARTER_OF_THE_YEAR: {
      return getFiscalFormat($t(getQuarter(periodValue)), isFiscal)
    }

    case DIMENSION_TITLES.DATE_FISCAL_MONTH_OF_THE_FISCAL_YEAR:
      return $t(getMonth(getMonthFromFiscalMonthOfTheYear(periodValue)))

    case DIMENSION_TITLES.DATE_MONTH_OF_THE_YEAR: {
      return $t(getMonth(periodValue))
    }

    case DIMENSION_TITLES.DATE_DAY_OF_THE_WEEK: {
      return $t(getDayOfTheWeek(periodValue))
    }

    case DIMENSION_TITLES.DATE_FISCAL_DAY_OF_THE_FISCAL_YEAR:
    case DIMENSION_TITLES.DATE_MONTH_OF_THE_QUARTER:
    case DIMENSION_TITLES.DATE_DAY_OF_THE_YEAR:
    case DIMENSION_TITLES.DATE_DAY_OF_THE_MONTH: {
      // Period value is a number
      return getFiscalFormat(periodValue.toString(), isFiscal)
    }
  }
}

const getDateRangeFromMainTimeDimension = (
  periodValue: string,
  mainTimeDimensionTitle: (typeof ALL_MAIN_TIME_DIMENSION_TITLES)[number]
): [Date, Date] => {
  switch (mainTimeDimensionTitle) {
    case DIMENSION_TITLES.DATE_DAY: {
      return [new Date(periodValue), new Date(periodValue)]
    }

    case DIMENSION_TITLES.DATE_WEEK: {
      const [isoYear, isoWeekNumber] = periodValue
        .split('.')
        .map(value => parseInt(value)) as [number, number]
      // Set the ISO week and ISO year (starting from the first day of the year)
      const dateInISOWeek = setISOWeek(new Date(isoYear, 0, 4), isoWeekNumber)
      const startOfIsoWeek = startOfISOWeek(dateInISOWeek)
      const endOfTheISOWeek = endOfISOWeek(dateInISOWeek)
      return [startOfIsoWeek, endOfTheISOWeek]
    }

    case DIMENSION_TITLES.DATE_MONTH: {
      const [year, month] = periodValue
        .split('.')
        .map(value => parseInt(value)) as [number, number]
      const firstDay = startOfMonth(new Date(year, month - 1))
      const lastDay = lastDayOfMonth(new Date(year, month - 1))
      return [firstDay, lastDay]
    }

    case DIMENSION_TITLES.DATE_QUARTER: {
      const [year, quarter] = periodValue
        .split('.')
        .map(value => parseInt(value)) as [number, number]

      const firstDay = startOfQuarter(setQuarter(new Date(year, 0, 1), quarter))
      const lastDay = lastDayOfQuarter(
        setQuarter(new Date(year, 0, 1), quarter)
      )
      return [firstDay, lastDay]
    }

    case DIMENSION_TITLES.DATE_YEAR: {
      const year = parseInt(periodValue)
      const firstDay = startOfYear(new Date(year, 0, 1))
      const lastDay = lastDayOfYear(new Date(year, 0, 1))
      return [firstDay, lastDay]
    }

    case DIMENSION_TITLES.DATE_FISCAL_YEAR: {
      return getFiscalYearDateRange(Number(periodValue))
    }

    case DIMENSION_TITLES.DATE_FISCAL_QUARTER: {
      return getFiscalQuarterDateRange(periodValue)
    }
  }
}

export const isPeriodInFuture = (
  periodValue: string,
  dimensionTitle: (typeof ALL_MAIN_TIME_DIMENSION_TITLES)[number]
): boolean => {
  const [, periodEndDate] = getDateRangeFromMainTimeDimension(
    periodValue,
    dimensionTitle
  )
  return isFuture(periodEndDate)
}

const getDateRangeFromSecondaryTimeDimension = (
  secondaryPeriodValue: string,
  secondaryTimeDimensionTitle: (typeof ALL_SECONDARY_TIME_DIMENSION_TITLES)[number],
  [mainPeriodStartDate, _]: [Date, Date]
): [Date, Date] => {
  const isFiscal = SECONDARY_FISCAL_TIME_DIMENSION_TITLES.includes(
    secondaryTimeDimensionTitle as (typeof SECONDARY_FISCAL_TIME_DIMENSION_TITLES)[number]
  )

  // For now secondary time dimension time grain can only be 'day', 'month' or 'quarter'
  const secondaryDimensionTimeGrain = TIME_GRAIN_BY_TIME_DIMENSION[
    secondaryTimeDimensionTitle
  ] as TimeGrain.DAY | TimeGrain.MONTH | TimeGrain.QUARTER

  // Secondary time dimension values can only be number
  const secondaryPeriodOffset = parseInt(secondaryPeriodValue)

  switch (secondaryDimensionTimeGrain) {
    case TimeGrain.DAY: {
      const day = addDays(mainPeriodStartDate, secondaryPeriodOffset - 1)
      return [day, day]
    }

    case TimeGrain.MONTH: {
      const periodStartDate = addMonths(
        startOfMonth(mainPeriodStartDate),
        secondaryPeriodOffset - 1
      )
      const periodEndDate = lastDayOfMonth(periodStartDate)
      return [periodStartDate, periodEndDate]
    }

    case TimeGrain.QUARTER: {
      return getQuarterPeriodByYearAndQuarterNumber(
        getYearStartDate(mainPeriodStartDate, isFiscal),
        secondaryPeriodOffset
      )
    }
  }
}

export const computeDateRangeFromDimensionsWithValue = (
  dimensionsWithValue: DimensionWithValue[],
  [selectedStartDate, selectedEndDate]: [Date, Date]
): [string, string] | null => {
  const mainTimeDimensionWithValue = dimensionsWithValue.find(
    ([dimension, _]) => isMainTimeDimension(dimension.shortTitle)
  )

  if (!mainTimeDimensionWithValue) {
    return [formatDate2(selectedStartDate), formatDate2(selectedEndDate)]
  }

  const secondaryTimeDimensionWithValue = dimensionsWithValue.find(
    ([dimension, _]) => isSecondaryTimeDimension(dimension.shortTitle)
  )

  if (mainTimeDimensionWithValue[1] === null) {
    return null
  }

  const [mainPeriodStartDate, mainPeriodEndDate] =
    getDateRangeFromMainTimeDimension(
      mainTimeDimensionWithValue[1],
      mainTimeDimensionWithValue[0]
        .shortTitle as (typeof ALL_MAIN_TIME_DIMENSION_TITLES)[number]
    )

  let [periodStartDate, periodEndDate] = [
    mainPeriodStartDate,
    mainPeriodEndDate
  ]

  // If there is a secondary time dimension we use the secondary period included in the main one, if not we use the main period
  if (secondaryTimeDimensionWithValue) {
    if (secondaryTimeDimensionWithValue[1] === null) {
      return null
    }

    const [secondaryPeriodStartDate, secondaryPeriodEndDate] =
      getDateRangeFromSecondaryTimeDimension(
        secondaryTimeDimensionWithValue[1],
        secondaryTimeDimensionWithValue[0]
          .shortTitle as (typeof SECONDARY_DEFAULT_TIME_DIMENSION_TITLES)[number],
        [mainPeriodStartDate, mainPeriodEndDate]
      )

    // If secondary period is partially outside the main period it is invalid
    if (
      isDateBefore(secondaryPeriodStartDate, mainPeriodStartDate) ||
      isDateBefore(secondaryPeriodEndDate, mainPeriodStartDate) ||
      isDateAfter(secondaryPeriodStartDate, mainPeriodEndDate) ||
      isDateAfter(secondaryPeriodEndDate, mainPeriodEndDate)
    ) {
      return null
    }

    periodStartDate = secondaryPeriodStartDate
    periodEndDate = secondaryPeriodEndDate
  }

  // If the period is totally outside the selected date range we return null
  if (
    isDateBefore(periodEndDate, selectedStartDate) ||
    isDateAfter(periodStartDate, selectedEndDate)
  ) {
    return null
  }

  // If the period is partially outside selected date range we need to reduce it
  const [periodApplicableStartDate, periodApplicableEndDate] = [
    max([periodStartDate, selectedStartDate]),
    min([periodEndDate, selectedEndDate])
  ]

  return [
    formatDate2(periodApplicableStartDate),
    formatDate2(periodApplicableEndDate)
  ]
}

export const getMainTimeDimensionByTimeGrain = (
  timeGrain: TimeGrain
): DIMENSION_TITLES => {
  const { isInFiscalMode } = useCustomizationStore()

  const mainFiscalTimeDimension = MAIN_FISCAL_TIME_DIMENSION_TITLES.find(
    d => TIME_GRAIN_BY_TIME_DIMENSION[d] === timeGrain
  )

  const mainDefaultTimeDimension = MAIN_DEFAULT_TIME_DIMENSION_TITLES.find(
    d => TIME_GRAIN_BY_TIME_DIMENSION[d] === timeGrain
  )!

  return isInFiscalMode
    ? mainFiscalTimeDimension || mainDefaultTimeDimension
    : mainDefaultTimeDimension
}

export const getGranularityFromDimensions = (
  dimensionShortTitles: DIMENSION_TITLES[]
): TimeGrain | CustomTimeGrain | null => {
  const hasFiscalTimeDimension = dimensionShortTitles.some(dimension =>
    ALL_FISCAL_TIME_DIMENSION_TITLES.includes(
      dimension as (typeof ALL_FISCAL_TIME_DIMENSION_TITLES)[number]
    )
  )

  const timeDimensions = dimensionShortTitles.filter(dimension =>
    ALL_TIME_DIMENSION_TITLES.includes(
      dimension as (typeof ALL_TIME_DIMENSION_TITLES)[number]
    )
  )

  if (timeDimensions.length === 0) {
    return null
  }

  return getGranularity(
    ORDERED_TIME_GRAINS.find(timeGrain =>
      timeDimensions.find(
        dimensionShortTitle =>
          TIME_GRAIN_BY_TIME_DIMENSION[
            dimensionShortTitle as (typeof ALL_TIME_DIMENSION_TITLES)[number]
          ] === timeGrain
      )
    )!,
    hasFiscalTimeDimension
  )
}

const getMonthFromFiscalMonthOfTheYear = (fiscalMonthValue: number): number => {
  const { fiscalYearStartMonth } = useCustomizationStore()

  // month of the calendar year (0-11)
  return positiveMod(fiscalMonthValue - 1 + fiscalYearStartMonth - 1, 12) + 1
}

export const isMainTimeDimension = (dimensionTitle: string) => {
  return ALL_MAIN_TIME_DIMENSION_TITLES.includes(
    dimensionTitle as (typeof ALL_MAIN_TIME_DIMENSION_TITLES)[number]
  )
}

export const isSecondaryTimeDimension = (dimensionTitle: string) => {
  return ALL_SECONDARY_TIME_DIMENSION_TITLES.includes(
    dimensionTitle as (typeof ALL_SECONDARY_TIME_DIMENSION_TITLES)[number]
  )
}

export const hasMainTimeDimension = (dimensions: Dimension[]) => {
  return dimensions.some(dimension =>
    isMainTimeDimension(dimension.shortTitle)
  )!
}
