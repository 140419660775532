<template>
  <button
    class="reflect-button"
    :class="[
      small && 'reflect-button--small',
      disabled || loading
        ? 'reflect-button--disabled'
        : `reflect-button--${theme}`
    ]"
    :disabled="disabled || loading"
  >
    <Loader v-if="loading" />
    <slot />
  </button>
</template>

<script lang="ts">
import type { SlotsType } from 'vue'

export default {
  name: 'ReflectButton',
  props: {
    small: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  slots: Object as SlotsType<{
    default: {}
  }>
}
</script>

<style lang="scss" scoped>
$button-height: 40px;
$button-small-height: 32px;
$button-border: 2px;
$button-padding: 12px;
$button-small-padding: 8px;

.reflect-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $button-height;
  min-width: 72px;
  padding: 8px $button-padding;
  border-radius: 8px;
  @include font-text;
  @include font-size($font-size-regular);
  cursor: pointer;
  background-color: $button-primary;
  color: $text-inverse;
  transition: color $short-transition ease,
    background-color $short-transition ease;

  &--disabled {
    cursor: initial;
    background-color: $button-disabled;
  }

  &--small {
    height: $button-small-height;
    padding: 4px $button-small-padding;
    @include font-size($font-size-mini);
  }

  &--primary {
    background-color: $button-primary;
    color: $text-inverse;

    &:focus,
    &:hover {
      background-color: $button-secondary;
    }
  }

  &--secondary {
    background-color: $button-secondary;
    color: $text-inverse;

    &:focus,
    &:hover {
      background-color: $button-primary;
    }
  }

  &--tertiary {
    background-color: $button-tertiary;
    color: $text-primary;

    &:hover {
      background-color: $button-secondary;
      color: $text-inverse;
    }
  }

  &--quaternary {
    background-color: $button-quaternary;
    color: $text-inverse;
  }

  &:focus,
  &:hover {
    outline: 0;
  }
}
</style>
