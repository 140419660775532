import { DimensionSelectionContext } from '~/types/analytics'
import { type DimensionMeta, FilterOperator, DimensionType } from '~/types/cube'

export enum DIMENSION_TITLES {
  DATE = 'date.timestamp',

  DATE_YEAR = 'date.year',
  DATE_QUARTER = 'date.quarter',
  DATE_QUARTER_OF_THE_YEAR = 'date.quarterOfTheYear',
  DATE_MONTH = 'date.month',
  DATE_MONTH_OF_THE_YEAR = 'date.monthOfTheYear',
  DATE_MONTH_OF_THE_QUARTER = 'date.monthOfTheQuarter',
  DATE_WEEK = 'date.week',
  DATE_DAY = 'date.day',
  DATE_DAY_OF_THE_YEAR = 'date.dayOfTheYear',
  DATE_DAY_OF_THE_MONTH = 'date.dayOfTheMonth',
  DATE_DAY_OF_THE_WEEK = 'date.dayOfTheWeek',

  DATE_FISCAL_YEAR = 'date.fiscalYear',
  DATE_FISCAL_QUARTER = 'date.fiscalQuarter',
  DATE_FISCAL_QUARTER_OF_THE_FISCAL_YEAR = 'date.fiscalQuarterOfTheFiscalYear',
  DATE_FISCAL_MONTH_OF_THE_FISCAL_YEAR = 'date.fiscalMonthOfTheFiscalYear',
  DATE_FISCAL_DAY_OF_THE_FISCAL_YEAR = 'date.fiscalDayOfTheFiscalYear',

  EMPLOYEE_SUBORGANIZATION = 'employee.group.suborganization',
  EMPLOYEE_DEPARTMENT = 'employee.group.department',
  EMPLOYEE_LOCATION_OFFICE = 'employee.location.locationOffice',
  EMPLOYEE_LOCATION_REMOTE = 'employee.location.locationRemote',
  EMPLOYEE_GROUP_1 = 'employee.group.group1',
  EMPLOYEE_GROUP_2 = 'employee.group.group2',
  EMPLOYEE_GROUP_3 = 'employee.group.group3',
  EMPLOYEE_GROUP_4 = 'employee.group.group4',
  EMPLOYEE_GROUP_5 = 'employee.group.group5',
  EMPLOYEE_GROUP_6 = 'employee.group.group6',
  EMPLOYEE_GROUP_7 = 'employee.group.group7',
  EMPLOYEE_LOCATION_1 = 'employee.location.location1',
  EMPLOYEE_LOCATION_2 = 'employee.location.location2',

  EMPLOYEE_DEPARTURE_REASON = 'employee.departure.reason',
  EMPLOYEE_DISPLAY_NAME = 'employee.info.displayName',
  EMPLOYEE_DISPLAY_NAME_WITH_ID = 'employee.info.displayNameWithId',
  EMPLOYEE_AGE_RANGE = 'employee.info.ageRange',
  EMPLOYEE_GENDER = 'employee.info.gender',
  EMPLOYEE_NATIONALITY = 'employee.info.nationality',
  EMPLOYEE_BIRTH_DATE = 'employee.info.birthDate',
  EMPLOYEE_EMPLOYMENT_TYPE = 'employee.contract.employmentType',
  EMPLOYEE_PROFESSIONAL_CATEGORY = 'employee.contract.professionalCategory',
  EMPLOYEE_PROFESSIONAL_CATEGORY_TYPE = 'employee.contract.professionalCategoryType',
  EMPLOYEE_IS_IN_HEADCOUNT = 'employee.contract.isInHeadcount',
  EMPLOYEE_JOB_TITLE = 'employee.contract.jobTitle',
  EMPLOYEE_SENIORITY = 'employee.arrival.seniority',
  EMPLOYEE_SENIORITY_IN_YEARS = 'employee.arrival.seniorityYears',
  EMPLOYEE_ARRIVAL_DATE = 'employee.arrival.date',
  EMPLOYEE_TRIAL_PERIOD_EVENT_TYPE = 'employee.trialPeriod.eventType',
  EMPLOYEE_IS_MANAGER = 'employee.manager.isManager',

  EMPLOYEE_IS_IN_ARRIVAL = 'employee.arrival.isInArrival',
  EMPLOYEE_IS_IN_DEPARTURE = 'employee.departure.isInDeparture',

  EMPLOYEE_CAREER_LEVEL = 'employee.career.level',
  EMPLOYEE_CAREER_SUB_LEVEL = 'employee.career.subLevel',
  EMPLOYEE_TIME_WITHOUT_PROMOTION = 'employee.career.timeWithoutPromotion',
  EMPLOYEE_CURRENT_PROMOTION_TIME = 'employee.career.currentPromotionTime',
  EMPLOYEE_NB_DAYS_FOR_CURRENT_JOB_TITLE = 'employee.career.nbDaysForCurrentJobTitle',
  EMPLOYEE_HAS_VERTICAL_MOBILITY = 'employee.career.hasVerticalMobility',

  EMPLOYEE_SALARY_BASE = 'employee.salary.base',
  EMPLOYEE_SALARY_BASE_EUR = 'employee.salary.baseEUR',
  EMPLOYEE_SALARY_TOTAL_RANGE_EUR = 'employee.salary.totalRangeEUR',
  EMPLOYEE_FTE_SALARY_TOTAL = 'employee.salary.fteSalaryTotal',
  EMPLOYEE_FTE_SALARY_TOTAL_EUR = 'employee.salary.fteSalaryTotalEUR',
  EMPLOYEE_SALARY_BASE_RAISE_RATE = 'employee.salaryRaise.salaryBaseRaiseRate',
  EMPLOYEE_TIME_WITHOUT_SALARY_RAISE = 'employee.salaryRaise.timeWithoutSalaryRaise',

  EMPLOYEE_PAYROLL_CATEGORY = 'employee.payroll.category',

  EMPLOYEE_ABSENCE_CATEGORY = 'employee.absence.category',
  EMPLOYEE_ABSENCE_SUBCATEGORY = 'employee.absence.subcategory',
  EMPLOYEE_ABSENCE_DURATION_RANGE = 'employee.absence.absenceDurationRange',

  EMPLOYEE_ANNUAL_PRESENCE_AT_MONTH_END = 'employee.yearly.annualPresenceAtMonthEnd',
  EMPLOYEE_IS_IN_EGAPRO_INDEX_CALCULATION = 'employee.egaproIndex.isInCalculation',
  EMPLOYEE_EGAPRO_INDEX_EXCLUSION_REASON = 'employee.egaproIndex.exclusionReason',
  EMPLOYEE_NB_DAYS_UNPLANNED_LEAVE_EGAPRO_INDEX = 'employee.egaproIndex.nbDaysUnplannedLeave',
  EMPLOYEE_AGE_RANGE_FOR_EGAPRO_INDEX = 'employee.egaproIndex.employeeAgeRangeForEgaproIndex',
  EMPLOYEE_TOTAL_ANNUAL_PAYROLL_EGAPRO_INDEX = 'employee.egaproIndex.totalAnnualPayroll',
  EMPLOYEE_ANNUAL_PAYROLL_CURRENCY_EGAPRO_INDEX = 'employee.egaproIndex.annualPayrollCurrency',
  EMPLOYEE_TOTAL_ANNUAL_PAYROLL_EUR_EGAPRO_INDEX = 'employee.egaproIndex.totalAnnualPayrollEUR',
  EMPLOYEE_LAST_SALARY_BASE_RAISE_RATE_EGAPRO_INDEX = 'employee.egaproIndex.lastSalaryBaseRaiseRate',
  EMPLOYEE_LAST_SALARY_RAISE_DATE_EGAPRO_INDEX = 'employee.egaproIndex.lastSalaryRaiseDate',
  EMPLOYEE_LAST_PROMOTION_DATE_EGAPRO_INDEX = 'employee.egaproIndex.lastPromotionDate',
  EMPLOYEE_MATERNITY_LEAVE_START_DATE = 'employee.egaproIndex.maternityLeaveStartDate',
  EMPLOYEE_MATERNITY_LEAVE_END_DATE = 'employee.egaproIndex.maternityLeaveEndDate',

  JOB_SUBORGANIZATION = 'recruitment.group.suborganization',
  JOB_DEPARTMENT = 'recruitment.group.department',
  JOB_GROUP_1 = 'recruitment.group.group1',
  JOB_GROUP_2 = 'recruitment.group.group2',
  JOB_GROUP_3 = 'recruitment.group.group3',
  JOB_LOCATION_1 = 'recruitment.location.location1',

  JOB_TITLE = 'recruitment.info.title',

  JOB_APPLICATION_CURRENT_STAGE_NAME = 'recruitment.stage.currentStageName',
  JOB_APPLICATION_HIRING_SOURCE = 'recruitment.sourcing.hiringSource',
  JOB_APPLICATION_HIRING_SOURCE_TYPE = 'recruitment.sourcing.hiringSourceType',
  JOB_APPLICATION_CANDIDATE_DISPLAY_NAME = 'recruitment.candidate.displayName',
  JOB_APPLICATION_CANDIDATE_DISPLAY_NAME_WITH_ID = 'recruitment.candidate.displayNameWithId',
  JOB_APPLICATION_REJECTION_REASON = 'recruitment.stage.rejectionReason',
  JOB_APPLICATION_IS_HIRED = 'recruitment.stage.isHired',
  JOB_APPLICATION_IS_HIRED_TODAY = 'recruitment.stage.isHiredToday',
  JOB_APPLICATION_OFFER_DATE = 'recruitment.timeline.offerDate',
  JOB_APPLICATION_IS_OFFERED = 'recruitment.stage.isOffered',
  JOB_APPLICATION_TIME_TO_OFFER = 'recruitment.speed.timeToOffer',
  JOB_APPLICATION_TIME_TO_HIRE = 'recruitment.speed.timeToHire',
  JOB_APPLICATION_TIME_TO_FIRST_FILL = 'recruitment.speed.timeToFirstFill',
  JOB_APPLICATION_TIME_TO_FILL_AND_ARCHIVE = 'recruitment.speed.timeToFillAndArchive',

  JOB_OWNER_DISPLAY_NAME = 'recruitment.jobOwner.displayName',
  JOB_APPLICATION_RECRUITER_DISPLAY_NAME = 'recruitment.recruiter.displayName',
  JOB_APPLICATION_HIRING_MANAGER_DISPLAY_NAME = 'recruitment.hiringManager.displayName'
}

export enum MEASURE_TITLES {
  EMPLOYEE_COUNT_END = 'employee.workforce.countEnd',
  EMPLOYEE_COUNT_AVERAGE = 'employee.workforce.countAverage',
  EMPLOYEE_COUNT_OVER_PERIOD = 'employee.workforce.countOverPeriod',

  EMPLOYEE_COUNT_END_FTE_HR = 'employee.workforce.countEndFteHR',
  EMPLOYEE_AVERAGE_FTE_HR = 'employee.workforce.countFteHRAverage',
  EMPLOYEE_COUNT_FINANCE = 'employee.payroll.countEmployeeFinance',
  EMPLOYEE_GROWTH_RATE = 'employee.workforce.employeeGrowthRate',
  EMPLOYEE_COUNT_AVERAGE_AT_MONTH_END = 'employee.workforce.countAverageAtMonthEnd',
  EMPLOYEE_COUNT_END_PERMANENT_WORKFORCE = 'employee.workforce.countEndPermanentWorkforce',

  EMPLOYEE_AVERAGE_AGE = 'employee.organization.avgAge',
  EMPLOYEE_COUNT_END_NATIONALITY = 'employee.organization.countEndNationality',
  EMPLOYEE_COUNT_CURRENCY = 'employee.payroll.countCurrency',
  EMPLOYEE_AVERAGE_TENURE = 'employee.tenure.avgTenure',
  EMPLOYEE_AVERAGE_TIME_STAY = 'employee.tenure.avgTimeStay',
  EMPLOYEE_COUNT_END_MANAGER = 'employee.management.countEndManager',
  EMPLOYEE_COUNT_OVER_PERIOD_MANAGER = 'employee.management.countOverPeriodManager',
  EMPLOYEE_RATIO_MANAGER = 'employee.management.ratioManager',
  EMPLOYEE_RATIO_MANAGER_OVER_PERIOD = 'employee.management.ratioManagerOverPeriod',
  EMPLOYEE_AVERAGE_SPAN_OF_CONTROL = 'employee.management.avgSpanOfControl',
  EMPLOYEE_MAX_SPAN_OF_CONTROL = 'employee.management.maxSpanOfControl',
  EMPLOYEE_RATIO_REMOTE = 'employee.remote.ratio',

  EMPLOYEE_COUNT_ARRIVAL = 'employee.arrival.count',
  EMPLOYEE_COUNT_DEPARTURE = 'employee.departure.count',
  EMPLOYEE_COUNT_NET_ARRIVAL = 'employee.arrival.countNetArrival',
  EMPLOYEE_COUNT_DEPARTURE_FINANCE = 'employee.departure.countFinance',
  EMPLOYEE_RATIO_DEPARTURE_REGRETTED = 'employee.departure.ratioRegretted',
  EMPLOYEE_ATTRITION = 'employee.retention.attrition',
  EMPLOYEE_ATTRITION_REGRETTED = 'employee.retention.attritionRegretted',
  EMPLOYEE_ATTRITION_NOT_REGRETTED = 'employee.retention.attritionNotRegretted',
  EMPLOYEE_RETENTION = 'employee.retention.retention',
  EMPLOYEE_TURNOVER = 'employee.retention.turnover',
  EMPLOYEE_COUNT_END_FIRST_YEAR = 'employee.firstYear.countEnd',
  EMPLOYEE_COUNT_END_FIRST_SIX_MONTHS = 'employee.firstSixMonths.countEnd',
  EMPLOYEE_FIRST_YEAR_NON_TRANSFORMATION_RATE = 'employee.firstYear.nonTransformationRate',
  EMPLOYEE_FIRST_SIX_MONTHS_NON_TRANSFORMATION_RATE = 'employee.firstSixMonths.nonTransformationRate',
  EMPLOYEE_RATIO_FIRST_YEAR = 'employee.firstYear.ratioFirstYear',
  EMPLOYEE_RATIO_FIRST_SIX_MONTHS = 'employee.firstSixMonths.ratioFirstSixMonths',
  EMPLOYEE_COUNT_END_TRIAL_PERIOD = 'employee.trialPeriod.countEnd',
  EMPLOYEE_RATIO_TRIAL_PERIOD = 'employee.trialPeriod.ratio',
  EMPLOYEE_TRIAL_PERIOD_NON_TRANSFORMATION_RATE = 'employee.trialPeriod.nonTransformationRate',
  EMPLOYEE_COUNT_TRIAL_PERIOD_EVENT = 'employee.trialPeriod.countEvent',

  EMPLOYEE_RATIO_GENDER_F = 'employee.gender.ratioGenderF',
  EMPLOYEE_RATIO_GENDER_M = 'employee.gender.ratioGenderM',
  EMPLOYEE_COUNT_END_F = 'employee.gender.countEndF',
  EMPLOYEE_COUNT_END_M = 'employee.gender.countEndM',
  EMPLOYEE_RATIO_ARRIVAL_F = 'employee.gender.ratioGenderArrivalF',
  EMPLOYEE_RATIO_ARRIVAL_M = 'employee.gender.ratioGenderArrivalM',
  EMPLOYEE_AVERAGE_SALARY_BASE_F = 'employee.gender.avgSalaryBaseEURF',
  EMPLOYEE_AVERAGE_SALARY_BASE_M = 'employee.gender.avgSalaryBaseEURM',
  EMPLOYEE_AVERAGE_SALARY_BASE_RAISE_RATE_F = 'employee.gender.avgSalaryBaseRaiseRateF',
  EMPLOYEE_AVERAGE_SALARY_BASE_RAISE_RATE_M = 'employee.gender.avgSalaryBaseRaiseRateM',
  EMPLOYEE_GENDER_BASE_SALARY_GAP_RATE = 'employee.gender.genderBaseSalaryGapRate',
  EMPLOYEE_RATIO_GENDER_MANAGER_F = 'employee.gender.ratioGenderManagerF',
  EMPLOYEE_RATIO_GENDER_MANAGER_M = 'employee.gender.ratioGenderManagerM',
  EMPLOYEE_RATIO_MANAGER_F = 'employee.gender.ratioManagerF',
  EMPLOYEE_RATIO_MANAGER_M = 'employee.gender.ratioManagerM',
  EMPLOYEE_ATTRITION_F = 'employee.gender.attritionF',
  EMPLOYEE_ATTRITION_M = 'employee.gender.attritionM',
  EMPLOYEE_TURNOVER_F = 'employee.gender.turnoverF',
  EMPLOYEE_TURNOVER_M = 'employee.gender.turnoverM',

  EMPLOYEE_MEDIAN_SALARY_TOTAL_EUR = 'employee.salary.medianTotal',
  EMPLOYEE_AVERAGE_SALARY_TOTAL_EUR = 'employee.salary.avgTotal',
  EMPLOYEE_MEDIAN_FTE_SALARY_TOTAL_EUR = 'employee.salary.medianFteSalaryTotalEUR',
  EMPLOYEE_AVERAGE_FTE_SALARY_TOTAL_EUR = 'employee.salary.avgFteSalaryTotalEUR',
  EMPLOYEE_SUM_SALARY_BASE_EUR = 'employee.salary.sumBase',
  EMPLOYEE_SUM_SALARY_VARIABLE_AND_BONUS_EUR = 'employee.salary.sumVariableAndBonus',
  EMPLOYEE_SUM_SALARY_TOTAL_EUR = 'employee.salary.sumTotal',
  EMPLOYEE_COUNT_SALARY_RAISE = 'employee.salary.countSalaryRaise',
  EMPLOYEE_RATIO_SALARY_RAISE = 'employee.salary.ratioSalaryRaise',
  EMPLOYEE_AVERAGE_TIME_TO_SALARY_BASE_RAISE = 'employee.salary.avgTimeToSalaryBaseRaise',
  EMPLOYEE_AVERAGE_SALARY_BASE_RAISE_RATE = 'employee.salary.avgSalaryBaseRaiseRate',
  EMPLOYEE_COUNT_EMPLOYEE_WITH_VARIABLE_SALARY = 'employee.salary.countEmployeeWithVariableSalary',
  EMPLOYEE_RATIO_EMPLOYEE_WITH_VARIABLE_SALARY = 'employee.salary.ratioEmployeeWithVariableSalary',
  EMPLOYEE_AVERAGE_TIME_WITHOUT_SALARY_RAISE = 'employee.salary.avgTimeWithoutSalaryRaise',

  EMPLOYEE_SUM_PAYROLL_BASE_GROSS_EUR = 'employee.payroll.sumPayrollBaseGrossEUR',
  EMPLOYEE_SUM_PAYROLL_VARIABLE_AND_BONUS_GROSS_EUR = 'employee.payroll.sumPayrollVariableAndBonusGrossEUR',
  EMPLOYEE_SUM_PAYROLL_TOTAL_GROSS_EUR = 'employee.payroll.sumPayrollTotalGrossEUR',

  EMPLOYEE_COUNT_VERTICAL_MOBILITY = 'employee.career.countVerticalMobility',
  EMPLOYEE_COUNT_LATERAL_MOBILITY = 'employee.career.countLateralMobility',
  EMPLOYEE_COUNT_GEOGRAPHIC_MOBILITY = 'employee.career.countGeographicMobility',
  EMPLOYEE_COUNT_NON_VERTICAL_MOBILITY = 'employee.career.countNonVerticalMobility',
  EMPLOYEE_AVERAGE_TIME_TO_PROMOTE = 'employee.career.avgTimeToPromote',
  EMPLOYEE_AVERAGE_TIME_SINCE_PROMOTE = 'employee.career.avgTimeSincePromote',
  EMPLOYEE_AVERAGE_TIME_IN_ROLE = 'employee.career.avgTimeInRole',
  EMPLOYEE_VERTICAL_MOBILITY_RATE = 'employee.career.verticalMobilityRate',
  EMPLOYEE_COUNT_END_CAREER_LEVEL = 'employee.organization.countEndCareerLevel',

  EMPLOYEE_SUM_WORKING_DAYS = 'employee.absence.sumWorkingDays',
  EMPLOYEE_ABSENTEEISM_RATE = 'employee.absence.absenteeismRate',
  EMPLOYEE_SUM_ABSENCE_AMOUNT = 'employee.absence.sumAmount',
  EMPLOYEE_SUM_ABSENCE_AMOUNT_UNPLANNED = 'employee.absence.sumAmountUnplanned',
  EMPLOYEE_RATIO_UNPLANNED_SHORT_ABSENCE = 'employee.absence.ratioUnplannedShort',
  EMPLOYEE_RATIO_EMPLOYEE_ABSENT = 'employee.absence.ratioEmployeeAbsent',
  EMPLOYEE_ABSENCE_FREQUENCY = 'employee.absence.absenceFrequency',
  EMPLOYEE_ABSENTEEISM_COST_RATE = 'employee.absence.absenteeismCostRate',
  EMPLOYEE_AVERAGE_ABSENCE_UNPLANNED_DURATION = 'employee.absence.avgAbsenceUnplannedDuration',
  EMPLOYEE_COUNT_ABSENCE_UNPLANNED = 'employee.absence.countAbsenceUnplanned',

  EMPLOYEE_COUNT_INCLUDED_IN_EGAPRO_INDEX = 'employee.egaproIndex.countIncludedInEgaproIndex',
  EMPLOYEE_COUNT_EXCLUDED_FROM_EGAPRO_INDEX = 'employee.egaproIndex.countExcludedFromEgaproIndex',
  EMPLOYEE_AVERAGE_TOTAL_ANNUAL_PAYROLL = 'employee.egaproIndex.avgTotalAnnualPayrollEUR',
  EMPLOYEE_COUNT_HAS_SALARY_RAISE_IN_YEAR = 'employee.egaproIndex.countHasSalaryRaisedInYear',
  EMPLOYEE_COUNT_HAS_SALARY_RAISE_IN_2_YEARS = 'employee.egaproIndex.countHasSalaryRaisedIn2Years',
  EMPLOYEE_COUNT_HAS_SALARY_RAISE_IN_3_YEARS = 'employee.egaproIndex.countHasSalaryRaisedIn3Years',
  EMPLOYEE_RATIO_SALARY_RAISE_IN_YEAR = 'employee.egaproIndex.ratioSalaryRaiseInYear',
  EMPLOYEE_COUNT_IS_PROMOTED = 'employee.egaproIndex.countIsPromoted',
  EMPLOYEE_RATIO_PROMOTION = 'employee.egaproIndex.ratioPromotion',
  EMPLOYEE_COUNT_HAS_RETURNED_FROM_MATERNITY_LEAVE = 'employee.egaproIndex.countHasReturnedFromMaternityLeave',
  EMPLOYEE_COUNT_HAS_RETURNED_FROM_MATERNITY_LEAVE_AND_HAS_BEEN_RAISED = 'employee.egaproIndex.countHasReturnedFromMaternityLeaveAndHasBeenRaised',

  RECRUITMENT_COUNT_HIRE = 'recruitment.overview.countHire',
  RECRUITMENT_COUNT_APPLY = 'recruitment.overview.countApply',
  RECRUITMENT_COUNT_OFFER = 'recruitment.overview.countOffer',
  RECRUITMENT_COUNT_REJECTED_OFFER = 'recruitment.overview.countRejectOffered',
  RECRUITMENT_COUNT_REJECTED_APPLY = 'recruitment.overview.countRejectApplied',
  RECRUITMENT_COUNT_END_JOB_OPEN = 'recruitment.overview.countEndJobOpen',
  RECRUITMENT_COUNT_INTERVIEWED_CANDIDATES = 'recruitment.overview.countInterviewedCandidates',

  RECRUITMENT_COUNT_INTERVIEW_IN_PROGRESS = 'recruitment.overview.countInterviewInProgress',
  RECRUITMENT_COUNT_END_OFFER_IN_PROGRESS = 'recruitment.overview.countEndOfferInProgress',
  RECRUITMENT_COUNT_END_APPLY_IN_PROGRESS = 'recruitment.overview.countEndApplyInProgress',
  RECRUITMENT_COUNT_END_UNPROCESSED_APPLY = 'recruitment.overview.countEndUnprocessedApply',
  RECRUITMENT_COUNT_END_SCREENING_IN_PROGRESS = 'recruitment.overview.countEndScreeningInProgress',
  RECRUITMENT_COUNT_END_INTERVIEW_1_IN_PROGRESS = 'recruitment.overview.countEndInterview1InProgress',
  RECRUITMENT_COUNT_END_INTERVIEW_2_IN_PROGRESS = 'recruitment.overview.countEndInterview2InProgress',
  RECRUITMENT_COUNT_END_INTERVIEW_3_IN_PROGRESS = 'recruitment.overview.countEndInterview3InProgress',
  RECRUITMENT_COUNT_END_INTERVIEW_4_IN_PROGRESS = 'recruitment.overview.countEndInterview4InProgress',

  RECRUITMENT_COUNT_SCREENING = 'recruitment.overview.countScreening',
  RECRUITMENT_COUNT_INTERVIEW_1 = 'recruitment.overview.countInterview1',
  RECRUITMENT_COUNT_INTERVIEW_2 = 'recruitment.overview.countInterview2',
  RECRUITMENT_COUNT_INTERVIEW_3 = 'recruitment.overview.countInterview3',
  RECRUITMENT_COUNT_INTERVIEW_4 = 'recruitment.overview.countInterview4',

  RECRUITMENT_GLOBAL_ACCEPTANCE_RATE = 'recruitment.funnel.globalAcceptanceRate',
  RECRUITMENT_OFFER_ACCEPTANCE_RATE = 'recruitment.funnel.offerAcceptanceRate',
  RECRUITMENT_GLOBAL_REJECTION_RATE = 'recruitment.funnel.globalRejectionRate',
  RECRUITMENT_WITHDRAWAL_RATE = 'recruitment.funnel.withdrawalRate',
  RECRUITMENT_OFFER_REJECTION_RATE = 'recruitment.funnel.offerRejectionRate',
  RECRUITMENT_NOT_CONTACTED_APPLY_RATIO = 'recruitment.overview.notContactedApplyRatio',

  RECRUITMENT_COUNT_APPLIED_TERMINATED = 'recruitment.funnel.countAppliedTerminated',
  RECRUITMENT_COUNT_SCREENED_TERMINATED = 'recruitment.funnel.countScreenedTerminated',
  RECRUITMENT_COUNT_INTERVIEWED_1_TERMINATED = 'recruitment.funnel.countEndInterviewed1Terminated',
  RECRUITMENT_COUNT_INTERVIEWED_2_TERMINATED = 'recruitment.funnel.countEndInterviewed2Terminated',
  RECRUITMENT_COUNT_INTERVIEWED_3_TERMINATED = 'recruitment.funnel.countEndInterviewed3Terminated',
  RECRUITMENT_COUNT_INTERVIEWED_4_TERMINATED = 'recruitment.funnel.countEndInterviewed4Terminated',

  RECRUITMENT_COUNT_OFFERED_TERMINATED = 'recruitment.funnel.countOfferedTerminated',
  RECRUITMENT_COUNT_HIRED_TERMINATED = 'recruitment.funnel.countHiredTerminated',
  RECRUITMENT_COUNT_INTERVIEWED_TERMINATED = 'recruitment.funnel.countInterviewedTerminated',

  RECRUITMENT_COUNT_BEFORE_OFFER_REJECTED_TERMINATED = 'recruitment.funnel.countBeforeOfferRejectedTerminated',

  RECRUITMENT_AVERAGE_TIME_TO_HIRE = 'recruitment.speed.avgTimeToHire',
  RECRUITMENT_AVERAGE_TIME_TO_FIRST_FILL = 'recruitment.speed.avgTimeToFirstFill',
  RECRUITMENT_AVERAGE_TIME_TO_FILL_AND_ARCHIVE = 'recruitment.speed.avgTimeToFillAndArchive',
  RECRUITMENT_AVERAGE_TIME_TO_OFFER = 'recruitment.speed.avgTimeToOffer',
  RECRUITMENT_AVERAGE_TIME_TO_INTERVIEW = 'recruitment.speed.avgTimeToInterview',
  RECRUITMENT_AVERAGE_TIME_TO_BEGIN_INTERVIEW = 'recruitment.speed.avgTimeToBeginInterview',

  RECRUITMENT_AVERAGE_STEP_TIME_FROM_APPLICATION_START_TO_SCREENING = 'recruitment.speed.avgStepTimeFromApplicationStartToScreening',
  RECRUITMENT_AVERAGE_STEP_TIME_FROM_SCREENING_TO_STEP_1 = 'recruitment.speed.avgStepTimeFromScreeningToStep1',
  RECRUITMENT_AVERAGE_STEP_TIME_FROM_STEP_1_TO_STEP_2 = 'recruitment.speed.avgStepTimeFromStep1ToStep2',
  RECRUITMENT_AVERAGE_STEP_TIME_FROM_STEP_2_TO_STEP_3 = 'recruitment.speed.avgStepTimeFromStep2ToStep3',
  RECRUITMENT_AVERAGE_STEP_TIME_FROM_STEP_3_TO_STEP_4 = 'recruitment.speed.avgStepTimeFromStep3ToStep4',
  RECRUITMENT_AVERAGE_STEP_TIME_FROM_INTERVIEW_END_TO_OFFER = 'recruitment.speed.avgStepTimeFromInterviewEndToOffer',
  RECRUITMENT_AVERAGE_STEP_TIME_FROM_OFFER_TO_HIRE = 'recruitment.speed.avgStepTimeFromOfferToHire',

  RECRUITMENT_AVERAGE_HIRES_BY_RECRUITER = 'recruitment.recruiter.avgHiresByRecruiter'
}

export const INITIAL_LIST_QUERY = {
  measures: [],
  dimensions: [],
  order: [],
  filters: []
}

export const ALL_DATA_TYPE = 'ALL'

export const DIMENSION_META_ATTRIBUTE_BY_CONTEXT: {
  [key in DimensionSelectionContext]: keyof DimensionMeta
} = {
  [DimensionSelectionContext.LIST]: 'showInTableExplorer',
  [DimensionSelectionContext.GENERIC]: 'showInGenericExplorer',
  [DimensionSelectionContext.FILTERS]: 'showInFilter',
  [DimensionSelectionContext.USER_SCOPE_FILTERS]: 'showInUserScopeFilter',
  [DimensionSelectionContext.LEGAL_REPORT_SCOPE_FILTERS]:
    'showInLegalReportScopeFilter'
}

export const FILTER_OPERATOR_ICON: { [key in FilterOperator]: string } = {
  [FilterOperator.EQUALS]: 'equal',
  [FilterOperator.NOT_EQUALS]: 'different',
  [FilterOperator.CONTAINS]: 'text',
  [FilterOperator.NOT_CONTAINS]: 'not-contains',
  [FilterOperator.BEFORE_DATE]: 'date-range',
  [FilterOperator.AFTER_DATE]: 'date-range',
  [FilterOperator.GT]: 'greater-than',
  [FilterOperator.LT]: 'less-than',
  [FilterOperator.GTE]: 'greater-or-equal',
  [FilterOperator.LTE]: 'less-or-equal',
  [FilterOperator.SET]: 'is-set',
  [FilterOperator.NOT_SET]: 'not-set',
  [FilterOperator.IN_DATE_RANGE]: 'date-range'
}

export const FILTER_OPERATORS_BY_DIMENSION_TYPE: {
  [key in DimensionType]: FilterOperator[]
} = {
  [DimensionType.NUMBER]: [
    FilterOperator.EQUALS,
    FilterOperator.NOT_EQUALS,
    FilterOperator.GT,
    FilterOperator.LT,
    FilterOperator.GTE,
    FilterOperator.LTE,
    FilterOperator.SET,
    FilterOperator.NOT_SET
  ],
  [DimensionType.STRING]: [
    FilterOperator.EQUALS,
    FilterOperator.NOT_EQUALS,
    FilterOperator.CONTAINS,
    FilterOperator.NOT_CONTAINS,
    FilterOperator.SET,
    FilterOperator.NOT_SET
  ],
  [DimensionType.TIME]: [
    FilterOperator.EQUALS,
    FilterOperator.NOT_EQUALS,
    FilterOperator.BEFORE_DATE,
    FilterOperator.AFTER_DATE,
    FilterOperator.SET,
    FilterOperator.NOT_SET
  ],
  [DimensionType.BOOLEAN]: [
    FilterOperator.EQUALS,
    FilterOperator.NOT_EQUALS,
    FilterOperator.SET,
    FilterOperator.NOT_SET
  ]
}

export const ALLOWED_USER_SCOPE_FILTER_OPERATORS = [
  FilterOperator.EQUALS,
  FilterOperator.NOT_EQUALS
]

export const SELECTION_MODAL_FIXED_HEIGHT = 620

export const SUGGESTED_MEMBER_OPTION_MAX_LENGTH = 12
