import { mergeAttributes, Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import Component from './EditorChart.vue'
import {
  CHART_ATTRIBUTES,
  CHART_NODE_NAME
} from '~/constants/legalReports/sectionContent'

export default Node.create({
  name: CHART_NODE_NAME,

  group: 'block',

  atom: true,

  addAttributes(): {
    [key in (typeof CHART_ATTRIBUTES)[keyof typeof CHART_ATTRIBUTES]]: {
      default: any
    }
  } {
    return {
      [CHART_ATTRIBUTES.DATA]: {
        default: []
      },
      [CHART_ATTRIBUTES.CHART_TYPE]: {
        default: null
      },
      [CHART_ATTRIBUTES.QUERY]: {
        default: null
      },
      [CHART_ATTRIBUTES.COMPUTED_AT]: {
        default: {}
      },
      [CHART_ATTRIBUTES.DISPLAY_AS_PERCENT]: {
        default: false
      },
      [CHART_ATTRIBUTES.SORT]: {
        default: false
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'chart'
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['chart', mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return VueNodeViewRenderer(Component)
  }
})
