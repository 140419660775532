<template>
  <div
    class="root"
    :class="{
      'root--scroll': isFullPage
    }"
  >
    <template v-if="!isMobileOrTablet">
      <SideMenu
        v-if="!isFullPage"
        :empty="isEmpty"
        class="menu"
      />
      <div class="root__content">
        <div
          v-if="isVirtual"
          class="banner"
        >
          <p class="banner__text">{{ $t('viewAs.banner', { email }) }}</p>
        </div>
        <NuxtLayout>
          <NuxtPage />
        </NuxtLayout>
      </div>
    </template>

    <MobileDefaultScreen v-else />

    <div id="action" />

    <ReflectModal
      v-if="isExpiredVirtualSessionModalOpen"
      :title-message="$t('viewAs.virtualSessionOver.title')"
      :with-close-icon="false"
    >
      <p class="expired-session-description">
        {{ $t('viewAs.virtualSessionOver.description') }}
      </p>
    </ReflectModal>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import { useRouterStore } from './stores/router'
import { useLayoutStore } from '~/stores/layout'
import { useCurrentUserStore } from '~/stores/currentUser'

export default {
  name: 'App',
  data(): { isMobileOrTablet: boolean } {
    return {
      isMobileOrTablet: false
    }
  },
  computed: {
    ...mapState(useLayoutStore, ['theme']),
    ...mapState(useCurrentUserStore, ['id', 'isVirtual', 'email']),
    ...mapState(useRouterStore, ['isExpiredVirtualSessionModalOpen']),
    isEmpty() {
      return !this.id
    },
    isFullPage() {
      return ['full-page'].includes(this.theme)
    }
  },
  created() {
    this.isMobileOrTablet = this.computeIsMobileOrTablet()
  },
  mounted() {
    window.addEventListener('resize', this.refreshIsMobileOrTablet)
  },
  unmounted() {
    window.removeEventListener('resize', this.refreshIsMobileOrTablet)
  },
  methods: {
    refreshIsMobileOrTablet() {
      this.isMobileOrTablet = this.computeIsMobileOrTablet()
    },
    computeIsMobileOrTablet() {
      // This breakpoint matches the one defined in _variables.scss file
      if (window.innerWidth <= 954) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.root {
  @include responsive-zoom;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  height: 100%;

  &--scroll {
    overflow: auto;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

@media print {
  .root {
    overflow: visible;
  }
}

.banner {
  display: flex;
  background-color: $banner-primary-background;
  padding: $margin-small 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  z-index: 2;

  &__text {
    @include font-text;
    @include font-size($font-size-normal);
    color: $text-inverse;
    text-align: center;
  }
}

.menu {
  z-index: 2;
  height: 100%;
  width: $side-width;
  min-width: $side-width;
}
</style>

<style lang="scss">
.page-enter-active,
.page-leave-active {
  transition: all $short-transition;
}

.layout-enter-active,
.layout-leave-active {
  transition: all $short-transition;
}

.expired-session-description {
  width: 480px;
  @include font-text;
  @include font-size($font-size-regular);
  color: $text-secondary;
  text-align: center;
}
</style>
