import { useGtm } from '@gtm-support/vue-gtm'
import { type CurrentUserAccount } from '~/types/account'
import { FETCH_CURRENT_USER_ACCOUNT_QUERY } from '~/gql/accounts/fetchCurrentUserAccountQuery'
import { UPDATE_ACCOUNT_HOME_MODULES_MUTATION } from '~/gql/accounts/updateAccountHomeModulesMutation'
import { Dashboard } from '~/constants/homeModules'
import { UserRole } from '~/types/permissions'
import { SourceAccessType } from '~/types/sources'

const BENCHMARK_UNAUTHORIZED_NAMES = ['sezane.com']

type State = CurrentUserAccount

const EMPTY_USER = {
  id: '',
  email: '',
  user: {
    status: ''
  },
  role: UserRole.NONE,
  permissions: [],
  company: null,
  scope: null,
  isServiceUser: null,
  homeModules: [],
  sourcePermissions: [],
  intercomHash: '',
  isVirtual: false
}

export const useCurrentUserStore = defineStore('currentUser', {
  state: (): State => ({ ...EMPTY_USER }),
  getters: {
    canAccessBenchmark(state: State): boolean {
      if (!state.company) {
        return false
      }
      return !BENCHMARK_UNAUTHORIZED_NAMES.includes(state.company.name)
    },
    isCurrentUserAdmin(state: State): boolean {
      return state.role === UserRole.ADMIN
    },
    hasEditorSourcePermission(state: State): boolean {
      return (
        state.sourcePermissions.some(
          permission => permission.sourceAccessType === SourceAccessType.EDITOR
        ) || this.isCurrentUserAdmin
      )
    }
  },
  actions: {
    resetCurrentUser() {
      this.updateUser({ ...EMPTY_USER })
      const app = useNuxtApp()
      app.$apiGqlClient.clearStore() // Clear gql cache
    },
    async loadCurrentUser() {
      const app = useNuxtApp()

      const account = (
        await app.$apiGqlClient.query({
          query: FETCH_CURRENT_USER_ACCOUNT_QUERY
        })
      ).data.me as CurrentUserAccount

      this.updateUser(account)

      const trackingData = {
        event: 'user-loaded',
        companyID: account.company ? account.company.id : null,
        companyName: account.company ? account.company.name : null,
        companyDomain: account.company ? account.company.domains[0] : null,
        email: account.email,
        userID: account.id,
        role: account.role,
        intercomHash: account.intercomHash
      }

      // Identify user for GTM and Intercom tracking
      const gtm = useGtm()
      gtm?.trackEvent(trackingData)

      // Identify user for June tracking
      app.$june.identify(account.id, trackingData)
    },
    updateUser(account: State) {
      this.id = account.id
      this.email = account.email
      this.user.status = account.user.status
      this.role = account.role
      this.permissions = account.permissions
      this.company = account.company
      this.scope = account.scope
      this.homeModules = account.homeModules
      this.sourcePermissions = account.sourcePermissions
      this.isServiceUser = account.isServiceUser
    },
    setToVirtualAccount() {
      this.isVirtual = true
    },
    async updateAccountHomeModules(newHomeModules: Dashboard[]) {
      const app = useNuxtApp()
      const userCurrentHomeModules = this.homeModules
      this.homeModules = newHomeModules
      try {
        await app.$apiGqlClient.mutate({
          mutation: UPDATE_ACCOUNT_HOME_MODULES_MUTATION,
          variables: {
            homeModules: newHomeModules
          }
        })
      } catch (error) {
        this.homeModules = userCurrentHomeModules
        throw error
      }
    },
    async removeUserHomeModule(moduleIdToRemove: Dashboard) {
      const newHomeModules = this.homeModules.filter(
        moduleId => moduleId !== moduleIdToRemove
      )
      await this.updateAccountHomeModules(newHomeModules)
    },
    async addUserHomeModule(moduleIdToAdd: Dashboard) {
      const newHomeModules = [...this.homeModules, moduleIdToAdd]
      await this.updateAccountHomeModules(newHomeModules)
    }
  }
})
