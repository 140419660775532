<template>
  <NuxtLink
    v-if="!isAbsoluteLink"
    :target="target"
    :to="redirectLink"
    class="link"
  >
    <slot />
  </NuxtLink>
  <a
    v-else
    :href="link"
    :target="target"
    :rel="rel"
    class="link"
  >
    <slot />
  </a>
</template>

<script lang="ts">
import type { SlotsType } from 'vue'
import type { QUERY_PARAMETERS } from '~/types/queryParameters'

export default {
  name: 'ReflectLink',
  props: {
    link: {
      type: String,
      required: true
    },
    target: {
      type: String,
      default: '_self'
    },
    rel: {
      type: String,
      default: ''
    },
    isAbsoluteLink: {
      type: Boolean,
      default: false
    },
    append: {
      type: Boolean,
      default: false
    },
    queryParamsToKeep: {
      type: [Array, null] as PropType<QUERY_PARAMETERS[] | null>,
      default: null
    },
    queryParamsToAdd: {
      type: Object as PropType<{
        [key in QUERY_PARAMETERS]?: string | undefined
      }>,
      default: null
    }
  },
  slots: Object as SlotsType<{
    default: {}
  }>,
  computed: {
    redirectLink() {
      const route = useRoute()
      const path = this.append ? `${route.path}/${this.link}` : this.link

      const keptQueryParams = this.queryParamsToKeep
        ? Object.fromEntries(
            this.queryParamsToKeep.map(queryParamKey => [
              queryParamKey,
              route.query[queryParamKey]
            ])
          )
        : route.query

      return {
        path,
        query: { ...keptQueryParams, ...(this.queryParamsToAdd || {}) }
      }
    }
  }
}
</script>

<style>
.link {
  display: block;
  cursor: pointer;
  /* stylelint-disable */
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  /* stylelint-enable */
}
</style>
