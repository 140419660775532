import { format } from 'date-fns'
import { PeriodType, type Period } from '~/types/timeDimension'

export enum PERIOD_ID {
  LAST_YEAR = 'LAST_YEAR',
  CURRENT_YEAR = 'CURRENT_YEAR',
  NEXT_YEAR = 'NEXT_YEAR',
  LAST_YEAR_Q1 = 'LAST_YEAR_Q1',
  LAST_YEAR_Q2 = 'LAST_YEAR_Q2',
  LAST_YEAR_Q3 = 'LAST_YEAR_Q3',
  LAST_YEAR_Q4 = 'LAST_YEAR_Q4',
  CURRENT_YEAR_Q1 = 'CURRENT_YEAR_Q1',
  CURRENT_YEAR_Q2 = 'CURRENT_YEAR_Q2',
  CURRENT_YEAR_Q3 = 'CURRENT_YEAR_Q3',
  CURRENT_YEAR_Q4 = 'CURRENT_YEAR_Q4',
  CURRENT_MONTH = 'CURRENT_MONTH',
  MTD = 'MTD',
  NEXT_YEAR_Q1 = 'NEXT_YEAR_Q1',
  QTD = 'QTD',
  YTD = 'YTD',
  LAST_12_MONTHS_TO_DATE = 'LAST_12_MONTHS_TO_DATE',
  LAST_MONTH = 'LAST_MONTH',
  CUSTOM = 'CUSTOM',
  UNTIL_NOW = 'UNTIL_NOW',
  LEGAL_REPORT_YEAR_1 = 'LEGAL_REPORT_YEAR_1',
  LEGAL_REPORT_YEAR_2 = 'LEGAL_REPORT_YEAR_2',
  LEGAL_REPORT_YEAR_3 = 'LEGAL_REPORT_YEAR_3'
}

export const UNTIL_NOW_PERIOD: Period = {
  periodId: PERIOD_ID.UNTIL_NOW,
  periodType: PeriodType.YEAR,
  dateRange: ['2019-01-01', format(new Date(), 'yyyy-MM-dd')],
  isFuture: false,
  // TODO DEV-1463 - currently patch - to not generate api error when UNTIL_NOW_PERIOD is accidentally use in dashboard - settings timeDimension store should be reset before but not working currently
  compareDateRange: ['2019-01-01', '2019-01-01'],
  trendDateRange: ['2019-01-01', '2019-01-01']
}

export const DASHBOARD_PERIOD_IDS = [
  PERIOD_ID.LAST_YEAR,
  PERIOD_ID.CURRENT_YEAR,
  PERIOD_ID.NEXT_YEAR,
  PERIOD_ID.LAST_YEAR_Q1,
  PERIOD_ID.LAST_YEAR_Q2,
  PERIOD_ID.LAST_YEAR_Q3,
  PERIOD_ID.LAST_YEAR_Q4,
  PERIOD_ID.CURRENT_YEAR_Q1,
  PERIOD_ID.CURRENT_YEAR_Q2,
  PERIOD_ID.CURRENT_YEAR_Q3,
  PERIOD_ID.CURRENT_YEAR_Q4,
  PERIOD_ID.CURRENT_MONTH,
  PERIOD_ID.MTD,
  PERIOD_ID.NEXT_YEAR_Q1,
  PERIOD_ID.QTD,
  PERIOD_ID.YTD,
  PERIOD_ID.LAST_12_MONTHS_TO_DATE,
  PERIOD_ID.LAST_MONTH
] as const
