import { updateQueryParams } from '~/services/router'
import { useCubeStore } from '~/stores/cube'
import { useFiltersStore } from '~/stores/filters'
import { ScopeType } from '~/types/permissions'
import { QUERY_PARAMETERS } from '~/types/queryParameters'

interface State {
  scopeType: ScopeType
}

export const useScopeStore = defineStore('scope', {
  state: (): State => ({
    scopeType: ScopeType.USER_SCOPE
  }),
  actions: {
    async toggleScopeType() {
      const newScopeType =
        this.scopeType === ScopeType.COMPANY
          ? ScopeType.USER_SCOPE
          : ScopeType.COMPANY

      this.scopeType = newScopeType

      const { loadMeta } = useCubeStore()
      await loadMeta()

      const filtersStore = useFiltersStore()
      filtersStore.getStandardFiltersOptions()

      await updateQueryParams({
        [QUERY_PARAMETERS.DASHBOARD_SCOPE_TYPE]: this.scopeType
      })
    },
    async setSelectedScopeType(
      scopeType: ScopeType = ScopeType.USER_SCOPE,
      shouldModifyUrlParams: boolean = true
    ) {
      this.scopeType = scopeType

      shouldModifyUrlParams &&
        (await updateQueryParams({
          [QUERY_PARAMETERS.DASHBOARD_SCOPE_TYPE]: this.scopeType
        }))
    }
  }
})
