import { ApolloClient, InMemoryCache, from } from 'apollo-boost'
import { createHttpLink } from 'apollo-link-http'
import VueApollo from 'vue-apollo'
import { setContext } from 'apollo-link-context'
import AuthenticationService from '~/services/AuthenticationService'

// TODO : Re-factorize the signup flow - https://www.notion.so/getreflect/Account-creation-resetting-v2-c9ec7224382b4907ba937bc21d1d3cc2
const PUBLIC_GQL_ROUTES = ['FETCH_INVITED_ACCOUNT_QUERY']

export default defineNuxtPlugin(nuxtApp => {
  const httpLink = createHttpLink({
    uri: `${nuxtApp.$config.public.API_BASE_URL}/graphql`
  })

  const authLink = setContext(async ({ operationName }, { headers }) => {
    const token =
      operationName &&
      !PUBLIC_GQL_ROUTES.includes(operationName) &&
      (await AuthenticationService.getAuthenticationToken())
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    }
  })

  const apolloClient = new ApolloClient({
    link: from([authLink, httpLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache'
      },
      query: {
        fetchPolicy: 'no-cache'
      }
    }
  })

  const apolloProvider = new VueApollo({
    defaultClient: apolloClient
  })

  return {
    provide: {
      apiGqlClient: apolloProvider.defaultClient
    }
  }
})
