import { CHART_TYPE, EXPLORER_TYPE } from '~/constants/renderTypes'
import {
  type ExplorerContentType,
  ExplorerTableContentType,
  ExplorerChartContentType
} from '~/types/legalReports/sectionContent'

const SHARED_ATTRIBUTES = {
  QUERY: 'query',
  COMPUTED_AT: 'computed-at'
} as const

export const TABLE_ATTRIBUTES = {
  ...SHARED_ATTRIBUTES,
  YEAR_TABLE_WITH_TREND: 'year-table-with-trend',
  WITH_TOTAL: 'with-total',
  FIRST_10_ONLY: 'first-10-only'
} as const

export const TABLE_ROW_ATTRIBUTES = {
  IS_HEADER_ROW: 'is-header-row',
  IS_TOTAL_ROW: 'is-total-row'
} as const

export const TABLE_CELL_ATTRIBUTES = {
  INITIAL_VALUE: 'initial-value',
  EDITED: 'edited',
  DIMENSIONS_WITH_VALUE: 'dimensions-with-value'
} as const

export const CHART_ATTRIBUTES = {
  ...SHARED_ATTRIBUTES,
  CHART_TYPE: 'chart-type',
  DATA: 'data',
  SORT: 'sort',
  DISPLAY_AS_PERCENT: 'display-as-percent'
} as const

export const CHART_NODE_NAME = 'chart'

export const ATTACHMENT_ZONE_NODE_NAME = 'attachmentZone'

export const ATTACHMENT_ZONE_ATTRIBUTES = {
  ATTACHMENT_IDS: 'attachment-ids',
  UPDATED_AT: 'updated-at'
} as const

export const EXPLORER_RENDERER_BY_CONTENT_TYPE: {
  [key in ExplorerContentType]: EXPLORER_TYPE
} = {
  [ExplorerTableContentType.YEAR_TABLE]: EXPLORER_TYPE.KPI,
  [ExplorerTableContentType.STANDARD_TABLE]: EXPLORER_TYPE.KPI,
  [ExplorerChartContentType.BAR_CHART]: EXPLORER_TYPE.BAR,
  [ExplorerChartContentType.DOUGHNUT_CHART]: EXPLORER_TYPE.DOUGHNUT
}

export const CHART_TYPE_BY_CONTENT_TYPE: {
  [key in ExplorerChartContentType]: CHART_TYPE
} = {
  [ExplorerChartContentType.BAR_CHART]: CHART_TYPE.BAR,
  [ExplorerChartContentType.DOUGHNUT_CHART]: CHART_TYPE.DOUGHNUT
}
