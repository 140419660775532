import type { ResultSetItemValue } from '~/types/query'
import {
  type Measure,
  type Dimension,
  FilterOperator,
  MeasureCategory,
  DimensionCategory,
  OrderValue
} from '~/types/cube'
import { MemberFormat } from '~/types/analyticsFormat'
import type { EXPLORER_TYPE } from '~/constants/renderTypes'
import type { ALL_DATA_TYPE } from '~/constants/analytics'
import type { MEASURE_FORMULAS } from '~/constants/formulas'
import { SourceType } from '~/types/sources'
import type { TranslationKeys } from '~/plugins/i18n'

export interface Stat {
  evolution?: number | null
  value: number | null
}

export interface Filter {
  member: string
  operator: FilterOperator
  values: string[]
}

export interface BenchmarkSegment {
  title: string
  value: string
}

export interface BenchmarkQuery {
  measureTitle: string
  startDate: string
  endDate: string
  segments: BenchmarkSegment[]
}

export interface BenchmarkValue {
  lowerBound: number
  upperBound: number
  probability: number
}

export interface BenchmarkQueryResult {
  trustLevel: number
  sampleSize: number
  analyticsValue: number
  values: BenchmarkValue[]
}

export enum DataType {
  EMPLOYEE = 'EMPLOYEE',
  RECRUITMENT = 'RECRUITMENT'
}

export type DataTypeOrAll = DataType | typeof ALL_DATA_TYPE

export interface ScopeFilter extends Filter {
  dataType: DataType
}

export interface Segment {
  segmentName: string
  segmentShortTitle: string
  options: string[]
}

export type MemberCategory = MeasureCategory | DimensionCategory

export type MeasureOption = Measure & {
  label: string
  defaultLabel: string
}

export type MeasureOptionWithCategory = MeasureOption & {
  dataType: DataType
  category: MeasureCategory
}

export type DimensionOption = Dimension & {
  label: string
  defaultLabel: string
}

export type DimensionOptionWithCategory = DimensionOption & {
  dataType: DataType | null
  category: DimensionCategory
}

export type MeasureOptionsCategory = {
  id: MeasureCategory
  dataType: DataType
  options: MeasureOption[]
}

export type DimensionOptionsCategory = {
  id: DimensionCategory
  dataType: DataType | null
  options: DimensionOption[]
}

export type MemberOptions = DimensionOption[] | MeasureOption[]

export type MemberOptionsWithCategory =
  | MeasureOptionWithCategory[]
  | DimensionOptionWithCategory[]

export type MemberOptionsCategories =
  | MeasureOptionsCategory[]
  | DimensionOptionsCategory[]

export interface ExplorerTableColumn {
  shortTitle: string
  name: string
  value: string
  format: MemberFormat
  type?: string
}

export interface SortingByColumn {
  [key: string]: OrderValue | null
}

export type DimensionValue = string | null

export type DimensionWithValue = [dimension: Dimension, value: DimensionValue]

export interface ExplorerDrilldownOptions {
  originalMeasureTitle: string
  measure: Measure
  dimensionsWithValue: DimensionWithValue[]
}

export interface OpenDrilldownEventBody {
  measureTitle: string
  dimensionValue: string | null
  groupValue: string | null
}

export type BaseDataSet = {
  key: string
  label: string
  data: (number | null)[]
  format: MemberFormat
}

export type DataSet = BaseDataSet & {
  groupValue: ResultSetItemValue | undefined
  groupValueLabel: string | undefined
  measureLabel: string
  measureTitle: string
}

export type ChartLabel = {
  formattedValue: string
  value: ResultSetItemValue
  isInFuture?: boolean
}

export type ChartData = {
  labels: ChartLabel[]
  datasets: DataSet[]
  isInFuture?: boolean[]
}

export type TrendChartData = {
  labels: string[]
  datasets: BaseDataSet[]
  isInFuture?: boolean[]
}

export interface ModuleExplorerLink {
  measure: string
  type: EXPLORER_TYPE
  dimension?: string
  group?: string
  sort?: boolean
}

export interface ModuleBenchmarkLink {
  measure: string
}

export interface ModuleInfoContent {
  source: SourceType
  definition: TranslationKeys
  boldDefinition?: TranslationKeys
  insight?: TranslationKeys
  learnMoreLink?: TranslationKeys
  calculation?: {
    legends: TranslationKeys[]
    formula: (typeof MEASURE_FORMULAS)[keyof typeof MEASURE_FORMULAS]
  }
}

export enum MemberType {
  DIMENSION = 'DIMENSION',
  MEASURE = 'MEASURE',
  GROUP = 'GROUP'
}

export enum DimensionSelectionContext {
  LIST = 'LIST',
  GENERIC = 'GENERIC',
  FILTERS = 'FILTERS',
  USER_SCOPE_FILTERS = 'USER_SCOPE_FILTERS',
  LEGAL_REPORT_SCOPE_FILTERS = 'LEGAL_REPORT_SCOPE_FILTERS'
}

export enum MeasureSelectionContext {
  LIST = 'LIST',
  BENCHMARK = 'BENCHMARK',
  GENERIC = 'GENERIC'
}

export type StandardFilterOption = {
  value: string | number
  label: string
}

export enum QueryTimeType {
  PERIOD_WITH_COMPARISON = 'PERIOD_WITH_COMPARISON',
  PERIOD_WITH_TREND_GRAIN = 'PERIOD_WITH_TREND_GRAIN',
  MULTI_YEAR_PERIOD = 'MULTI_YEAR_PERIOD',
  PERIOD = 'PERIOD',
  CUSTOM_PERIOD = 'CUSTOM_PERIOD'
}

export type QueryTimeOption =
  | {
      type: Exclude<QueryTimeType, QueryTimeType.CUSTOM_PERIOD>
    }
  | {
      type: QueryTimeType.CUSTOM_PERIOD
      dateRange: [string, string]
    }
