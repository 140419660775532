import { gql } from 'graphql-tag'

export const ANALYTICS_GET_QUERY = gql`
  query ANALYTICS_GET_QUERY(
    $query: AnalyticsQueryDto!
    $scopeType: ScopeType!
  ) {
    analyticsGetQuery(query: $query, scopeType: $scopeType) {
      results {
        data
        total
      }
    }
  }
`
