import {
  type Dimension,
  FilterOperator,
  MeasureCategory,
  DimensionCategory,
  type Measure
} from '~/types/cube'
import {
  type MemberCategory,
  MemberType,
  type ExplorerTableColumn,
  type MeasureOptionsCategory,
  DataType,
  type DimensionOptionsCategory,
  type DataTypeOrAll,
  DimensionSelectionContext,
  MeasureSelectionContext,
  type MeasureOption,
  type DimensionOption
} from '~/types/analytics'
import {
  AVAILABLE_MEASURE_CATEGORIES,
  DATA_TYPE_BY_MEASURE_CATEGORY,
  AVAILABLE_DIMENSION_CATEGORIES,
  DATA_TYPE_BY_DIMENSION_CATEGORY,
  AnalyticsCube,
  CUBE_DATA_TYPES,
  MEASURE_CATEGORY_EMOJIS,
  DIMENSION_CATEGORY_EMOJIS,
  ICON_BY_FORMAT
} from '~/constants/cube'
import { useCubeStore } from '~/stores/cube'
import { PermissionLevel } from '~/types/permissions'
import { MemberFormat } from '~/types/analyticsFormat'
import { useRenamingStore } from '~/stores/renaming'
import {
  DIMENSION_TITLES,
  DIMENSION_META_ATTRIBUTE_BY_CONTEXT,
  MEASURE_TITLES
} from '~/constants/analytics'
import { EXPLORER_TYPE } from '~/constants/renderTypes'
import { RenamingField } from '~/types/renaming'
import type { CustomizationField } from '~/types/customization'
import type {
  ExplicitQuery,
  MinimalQuery,
  PartialQuery,
  ResultSetItem
} from '~/types/query'
import { useCustomizationStore } from '~/stores/customization'
import { useTimeDimensionStore } from '~/stores/timeDimension'
import { filterAvailableTimeDimensions } from '~/services/timeDimension'
import type { TranslationKeys } from '~/plugins/i18n'
import type { SourceValueDimension } from '~/types/sourceValue'

const computeMeasureCategories = (
  measures: MeasureOption[]
): MeasureOptionsCategory[] => {
  return AVAILABLE_MEASURE_CATEGORIES.map(measureCategory => ({
    id: measureCategory,
    dataType: DATA_TYPE_BY_MEASURE_CATEGORY[measureCategory],
    options: measures.filter(
      measure => measure.meta.category === measureCategory
    )
  }))
}

const computeDimensionCategories = (
  dimensions: DimensionOption[]
): DimensionOptionsCategory[] => {
  return AVAILABLE_DIMENSION_CATEGORIES.map(dimensionCategory => ({
    id: dimensionCategory,
    dataType: DATA_TYPE_BY_DIMENSION_CATEGORY[dimensionCategory],
    options: dimensions.filter(
      dimension => dimension.meta.category === dimensionCategory
    )
  }))
}

export const getMeasureOptionFromMeasure = (
  measure: Measure,
  isListMeasure: boolean = false
): MeasureOption => {
  return {
    ...measure,
    label: isListMeasure
      ? getListMeasureTranslation(measure)
      : getDefaultMeasureTranslation(measure),
    defaultLabel: isListMeasure
      ? getListMeasureTranslation(measure, true)
      : getDefaultMeasureTranslation(measure, true)
  }
}

export const getDimensionOptionFromDimension = (
  dimension: Dimension
): DimensionOption => {
  return {
    ...dimension,
    label: getDimensionTranslation(dimension),
    defaultLabel: getDimensionTranslation(dimension, true)
  }
}

const sortMemberOptions = <T extends DimensionOption | MeasureOption>(
  memberOptions: T[]
): T[] => {
  return memberOptions.toSorted((memberA: T, memberB: T) => {
    if (memberA.meta.priority && !memberB.meta.priority) {
      return -1
    }

    if (memberB.meta.priority && !memberA.meta.priority) {
      return 1
    }

    if (
      memberA.meta.priority &&
      memberB.meta.priority &&
      memberA.meta.priority !== memberB.meta.priority
    ) {
      return memberA.meta.priority - memberB.meta.priority
    } else {
      return memberA.defaultLabel.localeCompare(memberB.defaultLabel)
    }
  })
}

export const getDefaultMeasureTranslation = (
  measure: Measure,
  withOriginalTranslation = false
) => {
  const { $t } = useNuxtApp()

  // TODO: Use 'TranslationKeys' type here
  const translationKey = `cube.measure.${
    measure.shortTitle as MEASURE_TITLES
  }.default`

  return $t(
    translationKey,
    getMemberFieldsTranslation(measure, withOriginalTranslation)
  )
}

export const getListMeasureTranslation = (
  measure: Measure,
  withOriginalTranslation = false
) => {
  if (measure.meta.associatedMeasure) {
    return ''
  }
  const { $t } = useNuxtApp()
  return $t(
    `cube.measure.${measure.shortTitle}.list`,
    getMemberFieldsTranslation(measure, withOriginalTranslation)
  )
}

export const getFunnelMeasureTranslation = (measure: Measure) => {
  const { $t } = useNuxtApp()
  return $t(
    `cube.measure.${measure.shortTitle}.funnel`,
    getMemberFieldsTranslation(measure)
  )
}

export const getDimensionTranslation = (
  dimension: Dimension,
  withOriginalTranslation = false
) => {
  const { $t } = useNuxtApp()

  const translationKey: TranslationKeys = `cube.dimension.${
    dimension.shortTitle as DIMENSION_TITLES
  }.default`

  return $t(
    translationKey,
    getMemberFieldsTranslation(dimension, withOriginalTranslation)
  )
}

const getMemberFieldsTranslation = (
  member: Dimension | Measure,
  withOriginalTranslation = false
) => {
  const renamingFields = member.meta.renamingFields
  return renamingFields
    ? Object.fromEntries(
        renamingFields.map(renamingField => [
          renamingField,
          getRenamingFieldTranslation(renamingField, withOriginalTranslation)
        ])
      )
    : {}
}

export const getAllRenamings = (withOriginalTranslation = false) => {
  return Object.fromEntries(
    Object.keys(RenamingField).map(renamingField => [
      renamingField,
      getRenamingFieldTranslation(
        renamingField as RenamingField,
        withOriginalTranslation
      )
    ])
  )
}

export const getRenamingFieldTranslation = (
  field: RenamingField,
  withOriginalTranslation = false
): string => {
  if (!withOriginalTranslation) {
    const { getRenamingByField } = useRenamingStore()
    const renaming = getRenamingByField(field)
    if (renaming) {
      return renaming
    }
  }

  const { $t } = useNuxtApp()
  const translationKey: TranslationKeys = `sharedAnalytics.renamingFields.${field}`
  return $t(translationKey)
}

export const getSourceValueDimensionTranslation = (
  dimensionKey: SourceValueDimension,
  isNormalization: boolean
) => {
  const { $t } = useNuxtApp()
  if (isNormalization) {
    return $t(
      `sharedAnalytics.sourceValueDimension.normalization.${dimensionKey}`
    )
  } else {
    return $t(`sharedAnalytics.sourceValueDimension.mapping.${dimensionKey}`)
  }
}

export const getTableViewsFilters = (measureTitle: string) => [
  // These two filters are used to remove values that do not affect the measure
  {
    member: measureTitle,
    operator: FilterOperator.NOT_EQUALS,
    values: ['0']
  },
  {
    member: measureTitle,
    operator: FilterOperator.SET,
    values: []
  }
]

export const getMeasureCategoryTranslation = (
  measureCategory: MeasureCategory
) => {
  const { $t } = useNuxtApp()
  const translationKey: TranslationKeys = `cube.measure.category.${measureCategory}`

  return `${MEASURE_CATEGORY_EMOJIS[measureCategory]} ${$t(translationKey)}`
}

export const getDimensionCategoryTranslation = (
  dimensionCategory: DimensionCategory
) => {
  const { $t } = useNuxtApp()
  const translationKey: TranslationKeys = `cube.dimension.category.${dimensionCategory}`

  return `${DIMENSION_CATEGORY_EMOJIS[dimensionCategory]} ${$t(translationKey)}`
}

export const getMemberCategoryTranslation = (
  category: MemberCategory,
  memberType: MemberType
) => {
  if (memberType === MemberType.MEASURE) {
    return getMeasureCategoryTranslation(category as MeasureCategory)
  }
  return getDimensionCategoryTranslation(category as DimensionCategory)
}

export const getDataTypeTranslation = (dataType: DataTypeOrAll) => {
  const { $t } = useNuxtApp()

  const translationKey: TranslationKeys = `sharedAnalytics.dataType.${dataType}`
  return $t(translationKey)
}

export const getMemberFormatIcon = (member: Dimension | Measure) => {
  if (member.meta.category === DimensionCategory.TIME) {
    return ICON_BY_FORMAT[MemberFormat.DATE]
  }
  return ICON_BY_FORMAT[member.meta.format ?? MemberFormat.NAME]
}

export const getColumns = (
  data: ResultSetItem[],
  includeMeasureAsColumn: boolean
): ExplorerTableColumn[] => {
  if (!data[0]) {
    return []
  }

  const firstRow = data[0]
  const { measuresMeta, dimensionsMeta } = useCubeStore()
  const allMembersMeta = [...measuresMeta!, ...dimensionsMeta!]

  const columns = Object.keys(firstRow).map(memberName => {
    const member = allMembersMeta.find(member => member.name === memberName)!
    const isDimension = dimensionsMeta!.some(
      member => member.name === memberName
    )

    return {
      shortTitle: member.shortTitle,
      name: member.name,
      value: isDimension
        ? getDimensionTranslation(member as Dimension)
        : getDefaultMeasureTranslation(member as Measure),
      format:
        member.meta && (member.meta.listAndKpiFormat || member.meta.format),
      type: member.type
    }
  })

  if (!includeMeasureAsColumn) {
    return columns.slice(0, -1)
  }
  return columns
}

export const getDimensionFromName = (dimensionName: string) => {
  const { dimensionsMeta } = useCubeStore()
  return dimensionsMeta!.find(dimension => dimension.name === dimensionName)!
}

export const getMemberFromTitle = (memberTitle: string) => {
  const { measuresMeta, dimensionsMeta } = useCubeStore()
  return [...dimensionsMeta!, ...measuresMeta!].find(
    member => member.shortTitle === memberTitle
  )
}

const getMembersFromNames = (memberNames: string[]) => {
  const { measuresMeta, dimensionsMeta } = useCubeStore()
  const allMeta = [...dimensionsMeta!, ...measuresMeta!]
  return memberNames.map(
    memberName => allMeta.find(memberMeta => memberMeta.name === memberName)!
  )
}

export const getMeasureFromTitle = (measureTitle: string) => {
  const { measuresMeta } = useCubeStore()

  return measuresMeta!.find(measure => measure.shortTitle === measureTitle)!
}

export const getDimensionFromTitle = (dimensionTitle: string) => {
  const { dimensionsMeta } = useCubeStore()
  return dimensionsMeta!.find(
    dimension => dimension.shortTitle === dimensionTitle
  )!
}

const getDimensionFromTitleAndCube = (
  dimensionTitle: string,
  cube: AnalyticsCube
): Dimension | undefined => {
  const { dimensionsMeta } = useCubeStore()
  return dimensionsMeta!.find(
    dimension =>
      dimension.shortTitle === dimensionTitle &&
      getCubeFromMeasureOrDimensionName(dimension.name) === cube
  )
}

export const getDimensionFromTitleAndMainCube = (
  dimensionTitle: string,
  queryMainCube: AnalyticsCube
) => {
  const mainCubeDimension = getDimensionFromTitleAndCube(
    dimensionTitle,
    queryMainCube
  )

  if (
    !mainCubeDimension &&
    CUBE_DATA_TYPES[queryMainCube] === DataType.EMPLOYEE &&
    queryMainCube !== AnalyticsCube.EMPLOYMENT_DAILY_SNAPSHOTS
  ) {
    return getDimensionFromTitleAndCube(
      dimensionTitle,
      AnalyticsCube.EMPLOYMENT_DAILY_SNAPSHOTS
    )
  } else {
    return mainCubeDimension
  }
}

export const getExplicitQueryFromQuery = (
  query: PartialQuery
): ExplicitQuery => {
  const measures = query.measures.map(measureTitle =>
    getMeasureFromTitle(measureTitle)
  ) as [Measure, ...Measure[]]

  const mainCube = getMainCubeFromQuery(query)!

  return {
    measures,
    dimensions:
      query.dimensions?.map(
        dimensionTitle =>
          getDimensionFromTitleAndMainCube(dimensionTitle, mainCube)!
      ) ?? []
  }
}

export const getDrillMembersFromMeasure = (measure: Measure) => {
  const { dimensionsMeta } = useCubeStore()
  const allAvailableDimensionTitles = dimensionsMeta!.map(dim => dim.shortTitle)

  const drillMembers = getMembersFromNames(measure.drillMembers).filter(
    drillMember => allAvailableDimensionTitles.includes(drillMember.shortTitle)
  )

  const filteredDrillMembers = drillMembers.filter(
    drillMember =>
      drillMember.meta.currentUserAccessType === PermissionLevel.FULL_ACCESS
  )

  if (filteredDrillMembers.length > 0) {
    return filteredDrillMembers.map(drillMember => drillMember.shortTitle)
  }

  return drillMembers.map(drillMember => drillMember.shortTitle)
}

export const getCubeFromMeasureOrDimensionName = (
  propertyName: string
): AnalyticsCube => {
  return propertyName.split('.')[0]! as AnalyticsCube
}

export const getCubeFromMeasureTitle = (
  measureTitle: string
): AnalyticsCube => {
  return getCubeFromMeasureOrDimensionName(
    getMeasureFromTitle(measureTitle).name
  )
}

export const getMainCubeFromQuery = (
  query: MinimalQuery
): AnalyticsCube | null => {
  if (query.measures && query.measures[0]) {
    return getCubeFromMeasureTitle(query.measures[0])
  }
  return null
}

export const getDataTypeFromMemberTitle = (memberTitle: string) =>
  memberTitle.split('.')[0]!.toUpperCase() as DataType

export const getUsableCubesByDataType = (dataType: DataType) => {
  return Object.values(AnalyticsCube).filter(
    cubeName => CUBE_DATA_TYPES[cubeName] === dataType
  )
}

export const getMeasureOptions = (
  measureSelectionContext: MeasureSelectionContext
) => {
  const { measuresMeta } = useCubeStore()
  const {
    isRecruitmentDataAvailable,
    isTrialPeriodDataAvailable,
    isManagerDataAvailable
  } = useCustomizationStore()

  const filteredMeasureOptions = measuresMeta!
    .filter(
      m =>
        m.isVisible &&
        m.meta.showInExplorer &&
        m.meta.currentUserAccessType === PermissionLevel.FULL_ACCESS &&
        (measureSelectionContext !== MeasureSelectionContext.LIST ||
          !m.meta.associatedMeasure) &&
        (measureSelectionContext !== MeasureSelectionContext.BENCHMARK ||
          m.meta.isBenchmarkable) &&
        (getDataTypeFromMemberTitle(m.shortTitle) !== DataType.RECRUITMENT ||
          isRecruitmentDataAvailable) &&
        (m.meta.category !== MeasureCategory.TRIAL_PERIOD ||
          isTrialPeriodDataAvailable) &&
        (m.meta.category !== MeasureCategory.MANAGEMENT ||
          isManagerDataAvailable)
    )
    .map(measure =>
      getMeasureOptionFromMeasure(
        measure,
        measureSelectionContext === MeasureSelectionContext.LIST
      )
    )

  const sortedMeasureOptions = sortMemberOptions(filteredMeasureOptions)

  return computeMeasureCategories(sortedMeasureOptions)
}

export const getDimensionOptions = ({
  usableCubes,
  dimensionSelectionContext,
  dimensionTitlesAlreadySelected = [],
  dimensionTitlesToExclude = [],
  keepYearDimension = false
}: {
  usableCubes: AnalyticsCube[]
  dimensionSelectionContext: DimensionSelectionContext
  dimensionTitlesAlreadySelected?: string[]
  dimensionTitlesToExclude?: string[]
  keepYearDimension?: boolean
}) => {
  const { dimensionsMeta } = useCubeStore()
  const {
    isRecruitmentDataAvailable,
    isTrialPeriodDataAvailable,
    isManagerDataAvailable
  } = useCustomizationStore()

  const nonUniqueDimensionOptions = dimensionsMeta!
    .filter(
      d =>
        d.isVisible &&
        d.meta[
          DIMENSION_META_ATTRIBUTE_BY_CONTEXT[dimensionSelectionContext]
        ] &&
        d.meta.currentUserAccessType === PermissionLevel.FULL_ACCESS &&
        (usableCubes.length === 0 ||
          usableCubes.includes(getCubeFromMeasureOrDimensionName(d.name))) &&
        (getDataTypeFromMemberTitle(d.shortTitle) !== DataType.RECRUITMENT ||
          isRecruitmentDataAvailable) &&
        (d.meta.category !== DimensionCategory.EMPLOYEE_TRIAL_PERIOD ||
          isTrialPeriodDataAvailable) &&
        (d.meta.category !== DimensionCategory.EMPLOYEE_MANAGER ||
          isManagerDataAvailable)
    )
    .map(dim => getDimensionOptionFromDimension(dim))

  let filteredDimensionOptions: DimensionOption[] = [
    ...new Map(nonUniqueDimensionOptions.map(d => [d.shortTitle, d])).values()
  ]

  filteredDimensionOptions = filteredDimensionOptions.filter(
    dimension =>
      ![
        ...dimensionTitlesToExclude,
        ...dimensionTitlesAlreadySelected
      ].includes(dimension.shortTitle)
  )

  if (
    dimensionSelectionContext === DimensionSelectionContext.GENERIC &&
    !keepYearDimension
  ) {
    const { selectedPeriod } = useTimeDimensionStore()
    filteredDimensionOptions = filterAvailableTimeDimensions(
      filteredDimensionOptions,
      dimensionTitlesAlreadySelected,
      selectedPeriod
    )
  }

  const sortedDimensionOptions = sortMemberOptions(filteredDimensionOptions)

  return computeDimensionCategories(sortedDimensionOptions)
}

export const buildExploreContextFromAIReturn = (
  measures: string[],
  dimensionsList: string[]
) => {
  const { measuresMeta } = useCubeStore()
  const isMeasureCanBeGrouped = measures.some(measureTitle =>
    measuresMeta?.some(
      measureMeta =>
        measureMeta.shortTitle === measureTitle && measureMeta.meta.canBeGrouped
    )
  )

  if (
    measures.length === 1 &&
    isMeasureCanBeGrouped &&
    dimensionsList.length === 1 &&
    dimensionsList[0] !== DIMENSION_TITLES.DATE
  ) {
    return {
      renderType: EXPLORER_TYPE.DOUGHNUT,
      dimensions: dimensionsList,
      groups: []
    }
  } else if (
    (dimensionsList.length === 2 && isMeasureCanBeGrouped) ||
    dimensionsList.length === 1
  ) {
    return {
      renderType: EXPLORER_TYPE.BAR,
      dimensions: dimensionsList.slice(0, dimensionsList.length - 1),
      groups: dimensionsList.slice(dimensionsList.length - 1)
    }
  } else {
    return {
      renderType: EXPLORER_TYPE.KPI,
      dimensions: dimensionsList.slice(0, dimensionsList.length - 1),
      groups: dimensionsList.slice(dimensionsList.length - 1)
    }
  }
}

export const getCustomizationFieldTranslation = (field: CustomizationField) => {
  const { $t } = useNuxtApp()
  const translationKey: TranslationKeys = `sharedAnalytics.customizationFields.${field}`
  return $t(translationKey)
}

export const getDimensionTranslationFromTitle = (dimensionTitle: string) => {
  return getDimensionTranslation(getDimensionFromTitle(dimensionTitle))
}
