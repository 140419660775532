import { HOME_MODULES_CONFIG_BY_ID } from '~/constants/homeModules'
import {
  DASHBOARD_QUERY_PARAMS,
  PAGE_ACCESS_BY_ROLE,
  ROUTE,
  SOURCE_PERMISSION_PAGE_ACCESS
} from '~/constants/routes'
import { useAnalyticsStore } from '~/stores/analytics'
import type { CurrentUserAccount } from '~/types/account'
import type { QUERY_PARAMETERS } from '~/types/queryParameters'

export const navigateToParentDashboard = async () => {
  const { currentDashboard } = useAnalyticsStore()
  if (currentDashboard) {
    await navigateToWithQueryParams({
      path: `${ROUTE.DASHBOARD}/${HOME_MODULES_CONFIG_BY_ID[currentDashboard].slug}`,
      queryParamsToKeep: DASHBOARD_QUERY_PARAMS
    })
  }
}

export const navigateToWithQueryParams = async ({
  path,
  queryParamsToKeep
}: {
  path: string
  queryParamsToKeep: QUERY_PARAMETERS[]
}) => {
  const route = useRoute()
  const query = Object.fromEntries(
    queryParamsToKeep.map(queryParamKey => [
      queryParamKey,
      route.query[queryParamKey] as string | undefined
    ])
  )
  await navigateTo({ path, query })
}

export const updateQueryParams = async (newParams: {
  [key in QUERY_PARAMETERS]?: string | null
}) => {
  // We use 'useRouter().currentRoute.value' instead of 'useRoute' here because it seems to be more up-to-date, especially when navigating from login page with a redirectUrl
  const currentRouteQuery = useRouter().currentRoute.value.query

  const updatedQuery = { ...currentRouteQuery, ...newParams }

  await navigateTo({ query: updatedQuery }, { replace: true })
}

export const hasUserAccessToPage = (
  user: CurrentUserAccount,
  path: string
): boolean => {
  const hasRoleAccessToPage = PAGE_ACCESS_BY_ROLE[user.role].some(route =>
    path.startsWith(route)
  )

  const hasSourcePermissionAccessToPage =
    user.sourcePermissions && user.sourcePermissions.length > 0
      ? SOURCE_PERMISSION_PAGE_ACCESS.some(route => path.startsWith(route))
      : false

  return (
    ROUTE.ROOT === path ||
    hasRoleAccessToPage ||
    hasSourcePermissionAccessToPage
  )
}
